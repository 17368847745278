@import 'src/styles/variables';
@import '~styles/main';

.campaigns {
  position: relative;
  background: #f5f5f5;
  min-height: calc(100vh - 58px);

  & .header {
    background: $white;
    height: 102px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: sticky;
    top: 48px;
    z-index: 3;

    &:global(._isFixed) {
      z-index: 1000;
    }
  }

  & .download {
    margin-right: 16px;
  }

  & .content {
    padding-top: 28px;
    padding-bottom: 40px;

    & .table {
      display: block;

      &:global(._isWheek) {
        :global(.load-item) {
          margin-left: 0;
        }

        & .tableHeader {
          .statBlock_item {
            :global(.TableFilterItem) {
              justify-content: flex-start;
            }
          }
          .filter {
            justify-content: center;
          }
        }

        & .totalStats {
          :global(.Statistics_item),
          .loadingItem {
            justify-content: flex-start;
          }
        }
      }

      & .tableHeader {
        display: flex;
        grid-gap: 0 20px;
        padding: 0 16px;
        padding-bottom: 13px;

        :global(.TableFilterItem) {
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          text-transform: uppercase;
          letter-spacing: 0.03em;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }

        .tabelHeaderLeft {
          @include leftPartForCampaignItem;

          .filter {
            width: 34px;
            text-align: center;
            img {
              vertical-align: middle;
            }
          }
        }

        &:global(.extend) {
          .tabelHeaderLeft {
            width: $widthLeftPartCampaignItemFull;
            @include mobileLeftSize;
          }
        }

        .statBlock {
          @include centerPartForCampaignItem;
          @include setGridTamplateForCampaignLine;

          &_item {
            @include calcSizeForCampaignItem;

            @include tablet {
              &:global(.isHide) {
                display: none;
              }
            }
          }
        }

        .tableHeaderRight {
          @include rightPartForCampaignItem;
          &::before {
            background: linear-gradient(
              to right,
              hsla(0, 0%, 96%, 0.46) 10%,
              #f5f5f5 100%
            );
          }
        }

        .actions {
          width: $widthRightPartCampaignItem;
          flex: none;

          @include tablet {
            width: $widthRightPartCampaignItemMobile;
          }
        }
      }

      & .totalStats {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        grid-gap: 0 20px;
        padding: 16px;
        padding-bottom: 13px;
        background: #ffffff;
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        height: 51px;

        @include tablet {
          font-weight: 700;
          font-size: 12px;
          line-height: 20px;
        }

        &_values {
          @include calcWidthForCenterPart;
          @include centerPartForCampaignItem;
          @include setGridTamplateForCampaignLine;

          :global(.Statistics_item) {
            display: flex;
            justify-content: flex-end;
            @include calcSizeForCampaignItem;

            &:global(._isGrey) {
              font-size: 12px !important;
              line-height: 12px !important;
              font-weight: 500;
              color: #828282;

              @include tablet {
                display: none;
              }
            }
          }

          .loadingItem {
            display: flex;
            justify-content: flex-end;
            @include calcSizeForCampaignItem;

            @include tablet {
              &:last-child {
                display: none;
              }
            }
          }
        }

        .tabelHeaderLeft {
          width: $widthLeftPartCampaignItem;
          flex: none;

          @include tablet {
            width: $widthLeftPartCampaignItemMobile;
          }
        }

        &:global(.extend) {
          .tabelHeaderLeft {
            width: $widthLeftPartCampaignItemFull;
            @include mobileLeftSize;
          }
          .totalStats_values {
            @include calcWidthForCenterPartFull;
          }
        }

        .actions {
          width: $widthRightPartCampaignItem;

          @include tablet {
            width: $widthRightPartCampaignItemMobile;
          }
        }
      }

      & .body {
        & > div {
          margin-bottom: 8px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      & .report {
        margin-bottom: 33px;
        position: relative;
        z-index: 1;

        &:global(._isShow) {
          :global(.ReportChart) {
            animation: showReport 0.2s linear 0.2s forwards;
          }
        }

        :global(.ReportChart) {
          transform: translateY(-40px);
          transition: all 1s linear;
          opacity: 0;
        }
      }
    }
  }

  .scroll {
    position: sticky;
    bottom: 2px;
    height: 8px;
    z-index: 2;
    display: flex;
    align-items: center;
    margin-top: 2px;

    ::-webkit-scrollbar {
      height: 8px;
    }

    &:global(._isFullScreen) {
      .scroll_left {
        width: $widthLeftPartCampaignItemFull;
        @include mobileLeftSize;
      }
    }

    &_wrapper {
      display: flex;
      align-items: center;
      grid-gap: 0 20px;
      padding: 0 16px;
    }

    &_left {
      @include leftPartForCampaignItem;
    }

    &_line {
      flex: 1 1;
      display: grid;
      align-items: center;
      overflow-x: auto;
      overflow-y: hidden;
      gap: 0 10px;

      @include setGridTamplateForCampaignLine;

      & > div {
        display: block;
        height: 1px;
        @include calcSizeForCampaignItem;
      }
    }

    &_right {
      @include rightPartForCampaignItem;
    }

    :global(.extend) {
      .scroll_left {
        width: $widthLeftPartCampaignItemFull;
        @include mobileLeftSize;
      }
    }
  }
}

@keyframes showReport {
  0% {
    transform: translateY(-40px);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

.status {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-transform: none;

  & .actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    & .search {
      margin-right: 6px;

      &:global(.isActive) {
        padding-right: 10px;
      }
    }

    & .resize {
      margin-right: 32px;
      cursor: pointer;
    }

    & .toggle-title {
      margin-right: 32px;

      &:global(.isDisabled) {
        pointer-events: none;

        svg {
          color: #828282;
        }
      }

      & .tooltip {
        margin-left: 0;

        & > div {
          &:last-child {
            top: calc(50% - 19px);
          }
        }
      }
    }

    & .filter {
      margin-right: 25px;
    }

    & .range {
      margin-right: 16px;
    }

    & .update,
    & .report_btn {
      margin-right: 16px;

      &:hover {
        cursor: pointer;
      }

      svg {
        vertical-align: middle;
      }
    }

    & .report_btn {
      &:global(._isActive) {
        svg {
          color: #025dff;
        }
      }
    }
  }
}

:global(._isToggleSearch) {
  .title {
    display: none;
  }
  .actions {
    flex: 1 0 auto;
  }
}

.item {
  &:global(.status) {
    cursor: pointer;
    justify-content: center !important;
    span {
      display: block;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #222222;
      margin-bottom: 4px;
    }

    a {
      color: #025dff;

      &:hover {
        text-decoration: none;
      }
      svg {
        margin-left: 6px;
      }
    }
  }
}

.filterList {
  cursor: default;
}
