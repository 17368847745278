@import 'src/styles/variables';

$height_item: 38px;
$left_padding_width: 450px;

$version: 570px;
/* Для мобильной версии*/
$version_MB: 270px;

@mixin illumination {
  content: '';
  position: absolute;
  left: -1px;
  top: -1px;
  border-width: 14px;
  border-style: solid;
  border-color: $tr;
  border-radius: 4px;
}

$illumination_blue: #e3ecff;
$illumination_white: #fff;
$illumination_green: #e6fce4;

:global(.full) {
  .row {
    & .item {
      padding: 0 6px 0 6px;
      justify-content: flex-end;

      @for $i from 1 to 100 {
        &:global(.size-#{$i}) {
          width: calc(28px + #{$i * 5}px);
          font-size: 14px;
          line-height: 14px;
        }
      }

      &:global(._isGrey) {
        font-size: 12px !important;
        line-height: 12px !important;
        font-weight: 500;
        color: #828282;
      }
    }
  }

  .fixed {
    & .number {
      .value {
        height: 28px;
        width: 28px;
      }
    }

    & .text {
      width: 100%;
    }
  }

  .actions {
    right: 0;
  }

  .hover {
    height: $height_item;
  }
}

.row {
  display: flex;
  align-items: center;

  &:hover {
    .hover {
      background-color: #e6fce4;
    }

    .fixed {
      background: $tr;

      .number {
        .value {
          border: 1px solid #ffffff;
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
          border-radius: 4px;
        }
      }
    }

    .item {
      position: relative;
      z-index: 5;
      background: $tr;
    }

    :global(.options) {
      background: $tr;

      &::before {
        display: none;
      }
    }
  }

  &:global(.isDeleted) {
    pointer-events: none;
    opacity: 0.7;
    .dropdown {
      .dropdown__menu {
        & > div {
          &:last-child {
            opacity: 0;
            z-index: 0;
            pointer-events: none;
          }
        }
      }
    }
  }

  :global(.options) {
    background: $white;
    border-radius: 1px;
  }

  &:global(.isActive) {
    .link {
      display: none;
    }

    :global(.options) {
      width: 50% !important;
    }

    & .fixed {
      width: 50% !important;
    }

    & .item {
      display: none;
    }
  }

  &:global(.fullTitle) {
    .fixed {
      width: $version !important;

      @include tablet {
        width: $version_MB !important;
      }

      .text {
        width: 410px;

        @include tablet {
          width: 144px;
        }
      }
    }

    &:global(.height-0) {
      a {
        line-height: 17px;
      }
    }

    @for $i from 1 to 100 {
      &:global(.height-#{$i}) {
        height: calc(46px + #{$i * 20}px) !important;

        .fixed,
        :global(.options) {
          height: calc(46px + #{$i * 20}px) !important;
        }
      }
    }
  }

  @keyframes last-edited-row {
    0% {
      background: #f4f5f6;
    }
    50% {
      background: $white;
    }
    100% {
      background: $white;
    }
  }

  @keyframes last-edited-indicator {
    0% {
      opacity: 1;
      top: 3px;
      bottom: 3px;
    }
    10% {
      opacity: 1;
      top: 2px;
      bottom: 2px;
    }
    100% {
      opacity: 0;
      top: 50%;
      bottom: 50%;
    }
  }

  &:global(.isLastEdited) {
    & .indicator {
      animation: last-edited-indicator 6s ease-out forwards;
    }
  }

  & .indicator {
    position: absolute;
    top: 50%;
    left: 2px;
    width: 4px;
    bottom: 50%;
    border-radius: 2px;
    background: $green;
    display: block;
    opacity: 0;
    z-index: 2;
  }

  & .item {
    flex: none;
    box-sizing: content-box;
    width: 115px;
    padding: 0 10px;
    align-self: stretch;
    display: flex;
    align-items: center;
    background: $white;
    justify-content: flex-end;

    @for $i from 1 to 100 {
      &:global(.size-#{$i}) {
        width: calc(40px + #{$i * 7}px);
      }
    }

    &:global(._isGrey) {
      font-size: 12px;
      line-height: 12px;
      color: #828282;
    }

    &:global(.center) {
      justify-content: center;
    }

    .last-child {
      padding-right: 22px;
    }
  }
}

.fixed {
  background: $white;
  display: flex;
  align-items: center;
  transition: 0s;

  &:hover {
    opacity: 1;
  }

  a {
    display: contents;
  }

  & .toggle__status {
    padding-left: 16px;
  }

  & .checkbox {
    padding-left: 12px;
  }

  & .number {
    padding-left: 16px;
    margin-right: 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: $black;
    box-sizing: content-box;
    height: 48px;
    display: flex;
    align-items: center;

    .value {
      height: 32px;
      width: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.2s;
      border: 1px solid $tr;
    }

    &:global(._customSetTarget) {
      .value {
        &::before {
          @include illumination;
          border-right-color: $illumination_green;
          border-bottom-color: $illumination_green;
          z-index: 0;
        }
        &::after {
          @include illumination;
          border-left-color: $illumination_white;
          border-top-color: $illumination_white;
          z-index: -1;
        }
      }
      &:global(._isUsualTheme) {
        .value {
          &::before {
            border-width: 16px;
          }
          &::after {
            border-width: 16px;
          }
        }
      }
    }

    &:global(._customSetPreTarget) {
      .value {
        &::before {
          @include illumination;
          border-left-color: $illumination_blue;
          border-top-color: $illumination_blue;
          z-index: 0;
        }
        &::after {
          @include illumination;
          border-right-color: $illumination_white;
          border-bottom-color: $illumination_white;
          z-index: -1;
        }
      }
      &:global(._isUsualTheme) {
        .value {
          &::before {
            border-width: 16px;
          }
          &::after {
            border-width: 16px;
          }
        }
      }
    }

    &:global(._customSetTargetAndPre) {
      .value {
        &::before {
          @include illumination;
          border-left-color: $illumination_blue;
          border-top-color: $illumination_blue;
          z-index: 0;
        }

        &::after {
          @include illumination;
          border-right-color: $illumination_green;
          border-bottom-color: $illumination_green;
          z-index: -1;
        }
      }
      &:global(._isUsualTheme) {
        .value {
          &::before {
            border-width: 16px;
          }
          &::after {
            border-width: 16px;
          }
        }
      }
    }
  }

  & .img {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
    padding-left: 22px;
    height: 48px;

    @include tablet {
      display: none;
    }

    & > div {
      &:first-child {
        z-index: 1;
        position: relative;
      }
    }

    :global(.icon) {
      div {
        display: flex;
        align-items: center;
      }
    }

    img {
      height: 15px;
      width: 15px;
      color: #c6c6c6;
    }
  }

  & .status {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px solid #fff;
    margin-left: 15px;
    margin-right: 23px;
    &:global(.bg-LAUNCHED) {
      background: $green;
    }
    &:global(.bg-STOPPED) {
      background: $orange;
    }
    &:global(.bg-DELETED) {
      background: $red;
    }
    &:global(.bg-ARCHIVE) {
      background: $yellow;
    }
  }

  & .text {
    width: 230px;
    font-weight: 500;
    padding-left: 22px;
    line-height: 48px;
    flex: none;

    @include tablet {
      width: 128px;
      padding-left: 12px;
    }

    & .tooltip {
      z-index: 10;
      margin: 0;
    }

    & .title {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #222222;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      word-break: break-all;
      text-overflow: ellipsis;

      @include tablet {
        -webkit-line-clamp: 2;
      }

      &:hover {
        opacity: 1;
      }

      &:global(.fullTitle) {
        text-overflow: initial;
        overflow: visible;
        display: block;

        @include tablet {
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
        }
      }
    }
    & .type {
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: #828282;
    }
  }
}

.link {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}

.icon {
  display: block;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 16px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  & + .text {
    max-width: calc(100% - 58px);
  }
}

.status {
  z-index: 2;

  & > * {
    position: relative;
    left: 0;

    &:nth-child(2) {
      left: -6px;
    }
    &:nth-child(3) {
      left: -12px;
    }
  }
}

.network {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 2px solid $white;
  background: #e2e2e2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #828282;
  }
}

.condition {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &:global(.isStopped) {
    & > div {
      border-color: #ff9c08;
      background: #fff2c5;
    }

    & > span {
      color: #ff9c08;
    }
  }

  & > div {
    display: block;
    margin-right: 14px;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    border: 2px solid $green;
    background: #c8fcc3;
  }

  & > span {
    font-size: 14px;
    line-height: 22px;
    color: $green;
  }
}

.moderation {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  & > div {
    margin-left: -4px;

    &:first-child {
      margin-left: 0;
    }
  }
}

.actions {
  display: grid;
  align-items: center;
  grid-template-columns: auto auto;
  position: absolute;
  right: 14px;
  top: 0;
  grid-gap: 15px;
  height: 100%;

  @include tablet {
    top: 0;
    right: 0;
    grid-template-columns: auto;
    width: 100%;
    justify-items: center;
  }

  & > div {
    &:last-child {
      z-index: 4;
    }
  }
}

.dropdown {
  z-index: 2;
  display: flex;

  @include tablet {
    display: none;
  }

  &:global(.isActive) {
    align-items: center;

    & .dropdown__show {
      border-radius: 50%;
      background: #f4f5f6;
      opacity: 1;
    }

    & .dropdown__menu {
      pointer-events: all;
      opacity: 1;

      & > div {
        z-index: 2;
        cursor: pointer;

        @keyframes show-item {
          0% {
            transform: translateY(40px);
            opacity: 0;
          }
          100% {
            transform: translateY(0px);
            opacity: 1;
          }
        }

        animation: show-item 0.2s cubic-bezier(0.4, 1.2, 0.6, 1.01) forwards;

        &:nth-last-child(1) {
          animation: none;
          opacity: 1;
        }
        @for $i from 2 to 10 {
          &:nth-last-child(#{$i}) {
            animation-delay: #{($i * 0.1) - 0.1}s;
          }
        }
      }
    }
  }

  &:global(._isNoAnimate) {
    &:global(.isActive) {
      & .dropdown__menu {
        animation: none;
        & > div {
          opacity: 1;
          animation: none;
        }
      }
    }
  }

  & .dropdown__show {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;

    svg {
      color: #828282;
      transition: color 0.1s;

      &:nth-child(1) {
        display: block;
      }
    }
  }

  & .dropdown__menu {
    position: absolute;
    top: 50%;
    right: 70px;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 25px;
    pointer-events: none;
    opacity: 1;
    transition: opacity 0.1s, transform 0.1s;
    transform: translateY(-50%);

    &:before {
      z-index: 1;
      content: '';
      position: absolute;
      top: -3px;
      bottom: -3px;
      left: 0;
      right: -37px;
    }

    & > div {
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
    }
  }
}

.toast {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: $black;
  width: 100%;
  word-break: break-word;
  display: flex;

  b,
  strong {
    font-weight: 700;
  }

  a {
    color: $blue;
  }

  & .icon {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    &:global(._isSuccess) {
      background: $green;
    }

    &:global(._isError) {
      background: $red;
    }
  }

  & .text {
    padding-left: 16px;
    width: calc(100% - 32px);

    span {
      color: $white;
    }

    &:global(._isError) {
      padding-top: 8px;
    }
  }

  & .links {
    margin-top: 10px;

    a {
      color: $black;
      line-height: 1;

      strong {
        text-decoration: underline;
        color: $blue;
        font-weight: 400;
        line-height: 1;
        padding-bottom: 0;
      }

      span {
        margin-right: 4px;
        color: $black !important;
      }
    }
  }
}

.hover {
  position: fixed;
  left: 0;
  height: 48px;
  background-color: $tr;
  width: 100%;
  z-index: 3;
}

@for $i from 1 to 100 {
  :global(.height-#{$i}) {
    .hover {
      height: calc(46px + #{$i * 20}px);
    }
  }
}
